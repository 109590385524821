import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Business Audit" />
    <HeroMini title="Business Audit"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Data Protection</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/data-protection.png"/>
    <p>Fusion provide Foundation and Practitioner Training for GDPR and Data Protection. We are able to provide in-house customised training to align exactly with your policies, processes, and systems. Fusion are experts in data, databases, processes and workflow means we are able to support the set-up of data inventories, subject access, breach logging and reporting and a range of other legal and regulatory functions.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Information Governance</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/emoji/48/000000/information-emoji.png"/>
    <p>Fusion has a deep understanding of data and information governance. We are able to help businesses through all stages of the Information Governance Maturity </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Cyber Security</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/cyber-security.png"/>
    <p>Fusion are expert in Cyber Security training (as part of GDPR and Data Protection) and also in the application of Microsoft tools, tags, configurations to control Confidentiality, Integrity and Access to data as part of policies, processes, systems, governance and compliance </p>
  </div>
</div>

<div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Business Continuity and Diaster Recovery</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/data-protection.png"/>
    <p>Fusion are able to support businesses with the design, documentation, testing and review of Business Continuity and Disaster Recovery. This includes desk-top case studies and full action scenarios to test and validate policies, processes, systems, governance and compliance.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont minServiceTitleHeight">AML / CFT</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/emoji/48/000000/information-emoji.png"/>
    <p>Fusion expertise in data, databases, processes and workflow means we are able to support businesses with AML / CFT logging, monitoring and reporting. We are also able to help with devising policies and processes as well as the roll-out of training.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont"></p>

  </div>
</div>



</div>
</main>
  </Layout>
)

export default ServicesPage
